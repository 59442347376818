<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <helpChat />
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    helpChat () { return import('@/components/chat/helpChat') }
  }
}
</script>
